<template>
    <div>
        <v-main fluid class="mt-n6">
            <div>
                <v-form ref="form"
                        v-model="valid"
                        class="w-100">

                    <v-container v-show="GetWhatToShow == 'view'" class="w-100 pa-8">
                        <kview form="fatura"
                               :viewname="GetViewName"
                               @rowclicked="ViewClicked($event, 'view')"></kview>
                    </v-container>
                    <v-container v-show="GetWhatToShow != 'view'" fluid class="pa-8">
                        <v-btn text tile color="primary" @click="BackToView">
                            <v-icon left>mdi-arrow-left</v-icon>{{$t("AL_Back")}}
                        </v-btn>
                        <v-row>
                            <v-col class="pb-0 mb-0">
                                <dynoform header="Faturalar"
                                          @change="UpdateFormField($event, 'formdata')"
                                          @changeGrid="UpdateExtData($event, $event.tableName)"
                                          @click="FormButtonClicked($event)"
                                          :attributes="GetFormAttributes"
                                          @SearchInputChanged="SearchInputChanged($event, 'formdata')"
                                          @RowButtonClicked="RowButtonClicked($event)"
                                          :tabname="Math.random().toString()"></dynoform>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-dialog v-model="actionBarWait"
                          persistent>
                    <v-card color="transparent"
                            dark class="py-5">
                        <v-card-text class="text-center py-5">
                            <v-progress-circular indeterminate
                                                 :size="50"
                                                 color="white"></v-progress-circular>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
            <v-footer class="grey lighten-3 pa-3" inset app padless>
                <v-progress-circular v-if="actionBarWait" :size="20" :width="2" rounded indeterminate color="primary" class="my-2 mr-2"></v-progress-circular>
                <v-toolbar-title v-if="actionBarWait" class="primary--text overline">{{$t("IM_YourTransactionIsInProgress")}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-show="GetWhatToShow != 'view'" v-for="(actionInfo, index) in GetFormAction"
                       :loading="actionBarWait"
                       @click="ApprovedAction(actionInfo)"
                       depressed
                       class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize"
                       :class="index == GetFormAction.length - 1 ? 'primary' : 'secondary'"
                       :key="actionInfo.action">
                    {{ $t(actionInfo.label) }}
                </v-btn>
            </v-footer>

            <v-dialog v-model="showDetailDialog" class="pb-0 mb-0" width="800" height="400">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col class="pb-0 mb-0" style="height: 600px">
                                <dynoform header="KDV/Stopaj Detay Bilgileri"
                                          @change="UpdateFormField($event, 'dynamicFormData')"
                                          @changeGrid="UpdateExtData($event, 'dynamicFormData')"
                                          @click="FormButtonClicked($event)"
                                          @SearchInputChanged="SearchInputChanged($event, 'dynamicFormData')"
                                          @RowButtonClicked="RowButtonClicked($event)"
                                          :tabname="Math.random().toString()"
                                          :tableName="'dynamicFormData'"
                                          :formInfo="DynamicFormInfo"></dynoform>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="showDetailDialog = false"
                               depressed
                               class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize secondary">
                            Kapat
                        </v-btn>
                        <v-btn @click="UpdateDynamicForm()"
                               depressed
                               class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize primary">
                            Kaydet
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-main>
    </div>
</template>

<style>
    .grey-lighten-4 {
        background-color: #f5f5f5;
    }

    .v-dialog {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
</style>

<script>

    import Utilities from '@/Utilities.js'
    import Vue from 'vue';
    import Vuex from 'vuex';
    Vue.use(Vuex);

    var sal_kalem_detay = {
        tableName: 'sal_kalem_detay',
        columns: {
            ad: {
                type: 'text',
                name: 'ad',
                label: 'FL_ItemName',
                edit: '',
                validateMin: 1,
            },
            kdv:
            {
                type: 'select',
                name: 'kdv',
                label: 'FL_VAT',
                group: 2,
                sira: 15,
                col: '6',
                edit: '?',
                options: [
                    { text: 'OP_PleaseSelect', value: '' }
                ],
            },
            talepmiktar: {
                type: 'text',
                name: 'talepmiktar',
                label: 'FL_Quantity',
                edit: '?',
                validateMin: 1,
            },
            talepbirim: {
                type: 'text',
                name: 'talepbirim',
                label: 'FL_Unit',
                edit: '',
                validateMin: 1,
                validateMax: 1,
            },
            alisfiyat: {
                type: 'number',
                name: 'alisfiyat',
                label: 'FL_UnitPrice',
                edit: '?',
                validateMin: 1,
                align: 'right'
            },
            doviz: {
                type: 'select',
                name: 'doviz',
                label: 'FL_Currency',
                edit: '?',
                validateMin: 1,
                validateMax: 1,
            },
            taleptutar: {
                type: 'number',
                name: 'taleptutar',
                label: 'FL_Amount',
                edit: '',
                hide: '?',
                validateMin: 1,
                align: 'right'
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            detaybutton: {
                type: 'button',
                label: 'FL_HoldStoppageInfo',
                name: 'detaybutton',
                edit: '01X2',
                width: 5,
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                miktar: { items: [], error: false, readonly: false, hidden: false },
                paracinsi: { items: [], error: false, readonly: false, hidden: false },
                alisfiyat: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        initialColumns: {
            taleptutar: {
                type: 'number',
                name: 'taleptutar',
                label: 'FL_Amount',
                edit: '',
                hide: '?',
                validateMin: 1,
                align: 'right'
            },
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'Fatura_Kalemler'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };


    var sal_kurlar = {
        tableName: 'sal_kurlar',
        columns: {
            doviz: {
                type: 'text',
                name: 'doviz',
                label: 'FL_Currency',
                edit: '',
                validateMin: 1,
            },
            tip:
            {
                type: 'select',
                name: 'tip',
                label: 'FL_CurrencyRateType',
                group: 2,
                sira: 15,
                col: '6',
                edit: '?',
                options: [
                    { text: 'OP_PleaseSelect', value: '' }
                ],
            },
            dovizkur: {
                type: 'number',
                name: 'dovizkur',
                label: 'FL_CurrencyRate',
                edit: '?',
                validateMin: 1,
                readonly: true
            },
            tarih: {
                type: 'date',
                name: 'tarih',
                label: 'FL_Date',
                edit: '',
                validateMin: 1,
                validateMax: 1,
            },
            tipack: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'tipack',
                width: 0
            },
            form: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'form',
                width: 0
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
            rowid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'rowid',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                dovizkur: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        initialColumns: {
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'Fatura_Kurlar'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };
    var dynamic_formInfo = {
        tabs: [
            { order: 1, header: 'TH_ItemTaxDetails' },

        ],
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'TH_ItemTaxDetails'
            }
        ],
        Columns: {
            taleptutar: {
                type: 'number',
                name: 'taleptutar',
                label: 'Bürüt Tutar',
                edit: '0',
                width: 55,
                col: '6',
                group: 1,
                sira: 5,

            },

            hesaplananodmetutari:
            {
                type: 'number',
                name: 'hesaplananodmetutari',
                label: 'Ödeme Tutari',
                edit: '',
                width: 55,
                col: '6',
                group: 1,
                sira: 5,
            },

            stopaj: {
                type: 'select',
                name: 'stopaj',
                label: 'Stopaj Orani',
                edit: '?',
                width: 55,
                col: '6',
                group: 1,
                sira: 5,
                options:
                    [
                        { text: 'Seçiniz', value: '' },
                        { text: "%0", value: "0" },
                        { text: "%5", value: "5" },
                        { text: "%10", value: "10" },
                        { text: "%15", value: "15" },
                        { text: "%17", value: "17" },
                        { text: "%20", value: "20" }
                    ]
            },
            stopajtutar: {
                type: 'number',
                name: 'stopajtutar',
                label: 'FL_TotalStoppage',
                edit: '',
                width: 55,
                col: '6',
                group: 1,
                sira: 5,
            },
            kdv: {
                type: 'int',
                name: 'kdv',
                label: 'FL_VAT',
                edit: '?',
                width: 55,
                col: '6',
                group: 1,
                sira: 5,

            },
            kdvtutari:
            {
                type: 'number',
                name: 'kdvtutari',
                label: 'KDV Tutarı',
                edit: '',
                width: 55,
                col: '6',
                group: 1,
                sira: 5,
            },
            tevkifat: {
                type: 'select',
                name: 'tevkifat',
                label: 'Tevkifat Oran',
                edit: '?',
                width: 55,
                col: '6',
                group: 1,
                sira: 5,
                options:
                    [
                        { text: 'Seçiniz', value: '' },
                        { text: "%0", value: "0" },
                        { text: "%10", value: "10" },
                        { text: "%20", value: "20" },
                        { text: "%30", value: "30" },
                        { text: "%40", value: "40" },
                        { text: "%50", value: "50" },
                        { text: "%70", value: "70" },
                        { text: "%80", value: "80" },
                        { text: "%90", value: "90" },
                    ]
            },
            tevkifattutari:
            {
                type: 'number',
                name: 'tevkifattutari',
                label: 'Tevkifat Tutari',
                edit: '',
                width: 55,
                col: '6',
                group: 1,
                sira: 5,
            },
            tevkifatKodAciklama:
            {
                type: 'hidden',
                name: 'tevkifatKodAciklama',
                label: 'Tevkifat Oran Kodları',
                edit: '?',
                width: 55,
                col: '6',
                group: 1,
                sira: 5,
               
            },


            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            }

        },
        InitialColumns: {
            id: {
                type: 'hidden',
                group: 1,
                sira: 10,
                name: 'id',
                label: 'FL_FormNo',
                edit: '',
                col: '6',
            },
        },
        form: 'DynamicFormInfo',
        parameters: [],
        attributes: {}
    };


    var fatura_formInfo = {
        tabs: [
            { order: 1, header: 'FN_Invoice' },
        ],
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'TH_FormInformation'
            },
            {
                tab: 1,
                order: 2,
                header: 'TH_InvoiceInformations'
            },
            {
                tab: 1,
                order: 3,
                header: 'FL_Notes'
            }
        ],

        Columns: {
            gidertipi:
            {
                type: 'select',
                name: 'gidertipi',
                label: 'Fatura Tipi',
                group: 1,
                sira: 5,
                col: '6',
                edit: '?',
                options: [
                    //Filled At Created Method
                ],
                validateMin: 1
            },
            durum: {
                type: 'text',
                group: 1,
                sira: 8,
                name: 'durum',
                label: 'FL_Status',
                edit: '',
                col: '6',
            },
            faturano:
            {
                type: 'text',
                group: 1,
                sira: 10,
                name: 'faturano',
                label: 'FL_InvoiceNumber',
                edit: '?',
                col: '6',
                validateMin: 1
            },
            irsaliyeno: {
                type: 'text',
                group: 1,
                sira: 12,
                name: 'irsaliyeno',
                label: 'FL_WaybillNo',
                edit: '',
                col: '3',
            },
            siparisnolar: {
                type: 'text',
                group: 1,
                sira: 14,
                name: 'siparisnolar',
                label: 'FL_OrderNo',
                edit: '',
                col: '3',
            },


            duzenlemetarihi: {
                type: 'date',
                group: 2,
                sira: 5,
                name: 'duzenlemetarihi',
                label: 'FL_ArrangementDate',
                edit: '?',
                col: '6',
                validateMin: 1,
                maxdate: new Date(Date.now()).toJSON()
            },

            sevktarihi: {
                type: 'date',
                group: 2,
                sira: 10,
                name: 'sevktarihi',
                label: 'FL_ShipmentDate',
                edit: '?',
                col: '6',
                validateMin: 1
            },

            odemedoviz: {
                type: 'text',
                group: 2,
                sira: 11,
                name: 'odemedoviz',
                label: 'FL_PaymentCurrency',
                edit: '',
                col: '6',
            },
            odemetarihi: {
                type: 'date',
                group: 2,
                sira: 12,
                name: 'odemetarihi',
                label: 'FL_PaymentDate',
                edit: '',
                col: '6',
                validateMin: 1,
                disabled: true
            },
            ek1file:
            {
                type: 'file',
                name: 'ek1file',
                label: 'FL_InvoiceCopy',
                group: 2,
                sira: 20,
                col: 12,
                edit: '?',
            },

            sal_kalem_detay: {
                type: 'grid',
                group: 2,
                sira: 25,
                name: 'sal_kalem_detay',
                label: '',
                edit: '',
                col: '12',
                showselect: false,
                buttons: []
            },

            kdvharic:
            {
                type: 'number',
                group: 2,
                sira: 30,
                name: 'kdvharic',
                label: 'FL_TotalAmount',
                edit: '',
                // hide: 'DE1X',
                col: '6',
            },

            toplamkdv:
            {
                type: 'number',
                group: 2,
                sira: 40,
                name: 'toplamkdv',
                label: 'FL_TotalVAT',
                edit: '',
                //    hide: 'DE1X',
                col: '6',
            },
            stopaj: {
                type: 'number',
                group: 2,
                sira: 44,
                name: 'stopaj',
                label: 'FL_TotalStoppage',
                edit: '',
                //  hide: 'DE1X',
                col: '6',
            },

            kdvtevkifat: {
                type: 'number',
                group: 2,
                sira: 42,
                name: 'kdvtevkifat',
                label: 'FL_TotalTaxWithHold',
                edit: '',
                //  hide: 'DE1X',
                col: '6',
            },

            geneltoplam:
            {
                type: 'number',
                group: 2,
                sira: 50,
                name: 'geneltoplam',
                label: 'FL_TotalAmountWithVAT',
                edit: '',
                //  hide: 'DE1X',
                col: '6',
            },

            toplamtutar:
            {
                type: 'number',
                group: 2,
                sira: 45,
                name: 'toplamtutar',
                label: 'FL_AmountToPay',
                edit: '',
                //     hide: 'DE1X',
                col: '6',
            },


            efaturaaciklama:
            {
                type: 'html',
                group: 2,
                sira: 70,
                name: 'efaturaaciklama',
                label: 'EFatura Bilgileri',
                edit: '',
                col: '6',
            },

            sal_kurlar: {
                type: 'grid',
                group: 2,
                sira: 85,
                name: 'sal_kurlar',
                label: 'FL_CurrencyRates',
                edit: '',
                col: '12',
                showselect: false,
                showExportToExcelButton: false,
                buttons: []
            },

            oonaynot: {
                type: 'history',
                group: 3,
                sira: 1,
                name: 'oonaynot',
                col: '12',
                edit: '?',
                label: ''
            },

            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
            efaturatoplam: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'efaturatoplam',
                width: 0
            },
        },
        form: 'fatura',
        formid: 0,
        viewName: 'taslak',
        viewparameter: '',
        ignoreActions: true,
        files: ['ek1file'],
        extdata: [sal_kalem_detay, sal_kurlar],
        actions: [],
        parameters: [],
        attributes: {},
        initialAttributes: {
            //kdvharic:
            //{
            //    items: [],
            //    readonly: false,
            //    hide: 'DE1X',
            //    error: false,
            //    disabled: true
            //},
            //toplamkdv:
            //{
            //    items: [],
            //    readonly: false,
            //    hide: 'DE1X',
            //    error: false,
            //    disabled: true
            //},
            //geneltoplam:
            //{
            //    items: [],
            //    readonly: false,
            //    hide: 'DE1X',
            //    error: false,
            //    disabled: true
            //},
            //toplamtutar:
            //{
            //    items: [],
            //    readonly: false,
            //    hide: 'DE1X',
            //    error: false,
            //    disabled: true
            //},
        }
    };

    import { mapGetters } from 'vuex';
    import dynoform from '@/components/DynoForm.vue';
    import grid from '@/components/grid.vue';
    import leftmenu from '@/components/leftmenu.vue';
    import kview from '@/components/view.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';
    import moment from 'moment';

    import showdialog from '@/components/ShowDialog.vue';
    Vue.component('showdialog', showdialog);

    import { acosh } from 'core-js/core/number';

    export default {
        name: 'fatura',
        mixins: [FormMixIn],
        components: {
            dynoform,
            leftmenu,
            kview,
        },
        props: {
            //form: String,
            formid: [Number, String],
            view: String,
            drawer: { type: Boolean, default: true },
            message: Object
        },
        data: function ()
        {
            return {
                valid: false,
                showDamgaEkFile: '0',
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
                viewName: '',
                username: '',
                form: '',
                formname: 'fatura',
                openallpanel: [0],
                currentform: 'fatura',
                errors: [],
                activeCurrencies: [],
                currencyDateData: {},
                showDetailDialog: false,
            };
        },
        methods: {
            FormLoaded: function ()
            {

                var currstate = this.GetState;
                this.currentState = this.currentState;
                if ("?".indexOf(currstate) != -1 && !this.actionBarWait)
                {
                    this.$store.commit('SetFormActions', [{ label: 'AL_Send', action: 'Gonder', type: 'external' }]);
                }
                else if ("I".indexOf(currstate) != -1 && !this.actionBarWait)
                {
                    this.$store.commit('SetFormActions', [{ label: 'AL_Withdraw', action: 'GeriCek', type: 'external' }]);
                }
                else
                {
                    this.$store.commit('SetFormActions', []);
                }

                if (this.GetFormField('gidertipi') != 'E')
                {
                    this.SetFieldHidden('efaturaaciklama');
                    this.ClearFieldDisabled('faturano');
                    this.ClearFieldDisabled('ek1file');
                    this.ClearFieldDisabled('gidertipi');
                }
                else
                {
                    this.SetFieldDisabled('faturano');
                    this.SetFieldDisabled('ek1file');
                    this.SetFieldDisabled('gidertipi');
                    this.ClearFieldHidden('efaturaaciklama');


                    setTimeout(() => {
                        var element = document.getElementById('__EfaturaOnOffButton');
                        if (element != null) {
                            element.style.display = "none";
                        }
                    }, 10);
                }

                if ("?I".indexOf(currstate) != -1 && !this.actionBarWait)
                {
                    if (sal_kalem_detay.columns.hasOwnProperty('taleptutar'))
                    {
                        delete sal_kalem_detay.columns['taleptutar'];
                    }
                }
                else
                {
                    if (!sal_kalem_detay.columns.hasOwnProperty('taleptutar'))
                    {
                        sal_kalem_detay.columns['taleptutar'] = sal_kalem_detay.initialColumns['taleptutar'];
                    }
                }
                this.activeCurrencies = [];
            },
            ExtDataLoaded: function (tableName)
            {
                if (tableName == 'sal_kalem_detay')
                {
                    var kalem_detay = this.GetExdata('sal_kalem_detay');

                    var flags = [];
                    for (var i = 0; i < kalem_detay.data.length; i++)
                    {
                        if (flags[kalem_detay.data[i].odemedoviz]) continue;
                        flags[kalem_detay.data[i].odemedoviz] = true;
                        this.activeCurrencies.push(kalem_detay.data[i].odemedoviz);
                    }

                    var payload = {};

                    if (this.activeCurrencies.length > 1)
                    {
                        payload.odemedoviz = "TL";
                        this.SetFieldHidden('sal_kurlar');
                    }
                    else
                    {
                        payload.odemedoviz = this.activeCurrencies.join(', ');
                        if (payload.odemedoviz == 'TL') {
                            this.SetFieldHidden('sal_kurlar');
                        }
                        else {
                            this.ClearFieldHidden('sal_kurlar');
                        }

                    }

                    this.$store.commit('SetFields', payload);

                    this.FilterCurrenciesData();

                    this.UpdateTotal();
                }
                else if (tableName == 'sal_kurlar')
                {
                    setTimeout(() =>
                    {
                        this.FilterCurrenciesData();
                        this.UpdateTotal();
                    }, 1000);
                }
            },
            FilterCurrenciesData: function ()
            {

                var exdataItems = this.GetExdataItems('sal_kurlar');
                var extDataItemsFiltered = []
                for (var i = 0; i < exdataItems.length; i++)
                {
                    var field = exdataItems[i];
                    for (var j = 0; j < this.activeCurrencies.length; j++)
                    {
                        if (field.doviz == this.activeCurrencies[j])
                        {
                            extDataItemsFiltered.push(field);
                        }
                    }
                }
                var duzenlemeTarihi = this.GetFormData["duzenlemetarihi"];
                for (var i = 0; i < extDataItemsFiltered.length; i++)
                {
                    if (duzenlemeTarihi == '')
                    {
                        duzenlemeTarihi = extDataItemsFiltered[i].tarih;
                    }
                    var dateKey = extDataItemsFiltered[i].doviz + this.getDateString(duzenlemeTarihi);
                    if (this.currencyDateData[dateKey] == undefined)
                    {
                        this.LoadCurrencyRate(extDataItemsFiltered[i].doviz, duzenlemeTarihi, dateKey);
                    }
                }

                var payload = {};
                payload.tableName = "sal_kurlar";
                payload.data = extDataItemsFiltered;
                payload.maxLen = extDataItemsFiltered.length;
                this.$store.commit('SetExtdataData', payload);

                for (var i = 0; i < extDataItemsFiltered.length; i++)
                {
                    if (extDataItemsFiltered[i].tip == 'SBST')
                    {
                        this.ClearExtDataFieldReadOnly('sal_kurlar', extDataItemsFiltered[i].rowid, 'dovizkur');
                    }
                    else
                    {
                        this.SetExtDataFieldReadOnly('sal_kurlar', extDataItemsFiltered[i].rowid, 'dovizkur');
                    }
                }
            },
            GetCurrencyRate: function (currency)
            {

                var exdataItems = this.GetExdataItems('sal_kurlar');
                var rate = 1;
                for (var i = 0; i < exdataItems.length; i++)
                {
                    var field = exdataItems[i];

                    if (field.doviz == currency)
                    {
                        rate = field.dovizkur;
                    }
                }

                return rate;
            },
            LoadCurrencyRate: function (currency, duzenlemeTarihi, dateKey, onSuccess)
            {
                var payload = {};
                payload.action = "GetCurrencyRate"
                payload.data = [{ "Currency": currency, "Date": duzenlemeTarihi }];
                var self = this;
                this.$store.dispatch("RunTime", payload)
                    .then(response =>
                    {
                        Utilities.log("Runtime  success result:", response);
                        self.currencyDateData[dateKey] = response.data.runTimeResult[0];
                        onSuccess(self.currencyDateData[dateKey]);
                    })
                    .catch(err =>
                    {
                        Utilities.log("Runtime erroro result:", err);

                    });

            },
            GetCurrencyRateFromData: function (currency, duzenlemeTarihi, onSuccess)
            {

                var dateKey = currency + this.getDateString(duzenlemeTarihi);
                var self = this;
                if (this.currencyDateData[dateKey] == undefined)
                {
                    this.LoadCurrencyRate(currency, duzenlemeTarihi, dateKey, function ()
                    {
                        onSuccess(self.currencyDateData[dateKey]);
                    });
                }
                else
                {
                    onSuccess(this.currencyDateData[dateKey]);
                }
            },
            getDateString: function (date)
            {
                var _date = new Date(date);

                return _date.getDate() + "/" + (_date.getMonth() + 1) + "/" + _date.getFullYear();
            },
            Selected: function ()
            {
                return;
            },

            NeedApprove: function (action)
            {
                var res;
                return res;
            },
            FormButtonClicked(col)
            {
                var name = col.name;
            },

            RowClicked: function (rowdata, table)
            {
                return;
            },


            ChangeAttribute: function (attribute, tableName)
            {
                var payload = { ...attribute, tableName };
                Utilities.log("Grid Emit changeAttribute", payload);
                this.$store.commit('SetFieldAttributes', payload);
            },
            ValidateForm: function (action)
            {
                this.ClearFieldErrors();
                this.errors = [];

                var columns = this.GetFormColumns;
                var formdata = this.GetFormData;
                var attributes = this.GetFormAttributes;

                var result = "";

                if (action.action == "Gonder")
                {
                    var escapeFields = ['id', 'kformsid', 'ek1file'];

                    for (var c in columns)
                    {
                        if (formdata[c] == null) formdata[c] = "";

                        var value = formdata[c].toString().trim();
                        var column = columns[c];
                        var attr = attributes[c];
                        var err = '';

                        if (attr.disabled == false)
                        {
                            if (!escapeFields.includes(column.name))
                            {
                                err = this.ValidateField(value, column);
                            }

                            if (err != '')
                            {
                                this.errors.push(err);
                                this.SetFieldError(c);
                                this.tab = 'Tab-1';
                            }
                        }
                    }
                }

                if (this.GetFormField('gidertipi') != 'E') {
                    if (this.GetFormField('ek1file') == '') {
                        this.errors.push(this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_InvoiceCopy') }));
                    }
                }
                else {
                    var toplamTutar = Number(this.GetFormField('toplamtutar')).toFixed(2);
                    var efaturaToplamTutar = Number(this.GetFormField('efaturatoplam')).toFixed(2);
                    if (Number(toplamTutar) + 1 > Number(efaturaToplamTutar) && Number(toplamTutar) - 1 < Number(efaturaToplamTutar)) {
                        //No problem
                    }
                    else {
                        this.errors.push("Toplam Odenecek Tutarın E-Faturadaki Toplam Odenecek Tutara esit olması gereklidir");
                    }

                }


                if (this.errors.length > 0)
                {
                    result = this.errors;
                }

                return result;
            },

            gidertipi_Field_Changed: function ()
            {
                if (this.GetFormField('gidertipi') != 'E') {
                    this.SetFieldHidden('efaturaaciklama');
                }
                else {
                    this.ClearFieldHidden('efaturaaciklama');


                    setTimeout(() => {
                        var element = document.getElementById('__EfaturaOnOffButton');
                        if (element != null) {
                            element.style.display = "none";
                        }
                    }, 10);
                }
            },
            duzenlemetarihi_Field_Changed: function (changeObj)
            {
                var exdataItems = this.GetExdataItems('sal_kurlar');
                var self = this;
                for (var i = 0; i < exdataItems.length; i++)
                {
                    var index = i;
                    this.GetCurrencyRateFromData(exdataItems[index].doviz, self.GetFormField('duzenlemetarihi'), function (data)
                    {
                        if (exdataItems[index].tip != 'SBST')
                        {
                            exdataItems[index].dovizkur = data[exdataItems[index].tip];
                        }
                        exdataItems[index].tarih = self.GetFormField('duzenlemetarihi');

                        var payload = {};
                        payload.tableName = "sal_kurlar";
                        payload.data = exdataItems;
                        payload.maxLen = exdataItems.length;
                        self.$store.commit('SetExtdataData', payload);

                        self.UpdateTotal();
                    });
                }
            },
            dynamicFormData_stopaj_Field_Changed: function (changeObj)
            {
                var line = this.GetFormDataFunction("dynamicFormData");

                var setFieldPayload = {};

                setFieldPayload.form = 'dynamicFormData';
                setFieldPayload.id = line.id;

                setFieldPayload["taleptutar"] = line.taleptutar;
                var stopajtutar = (changeObj.selectedObject / 100.0) * line.taleptutar;
                stopajtutar = stopajtutar.toFixed(2);
                setFieldPayload["stopajtutar"] = stopajtutar;

                setFieldPayload["kdv"] = line.kdv;
                var toplamkdv = ((line.kdv) / 100.0) * line.taleptutar;
                toplamkdv = toplamkdv.toFixed(2);
                setFieldPayload["kdvtutari"] = toplamkdv;

                setFieldPayload["tevkifat"] = line.tevkifat;
                var toplamtevkifat = (line.tevkifat / 100.0) * toplamkdv;
                toplamtevkifat = toplamtevkifat.toFixed(2);

                setFieldPayload["tevkifattutari"] = toplamtevkifat;

                setFieldPayload["tevkifatKodAciklama"] = line.tevkifatKodAciklama;
                setFieldPayload["hesaplananodmetutari"] = line.taleptutar - toplamtevkifat - stopajtutar;

                this.$store.commit('SetFields', setFieldPayload);
                this.UpdateTotal();

            },

            dynamicFormData_tevkifat_Field_Changed: function (changeObj)
            {
                var line = this.GetFormDataFunction("dynamicFormData");
 
                var setFieldPayload = {};

                setFieldPayload.form = 'dynamicFormData';
                setFieldPayload.id = line.id;

                setFieldPayload["taleptutar"] = line.taleptutar;
                var stopajtutar = (line.stopaj / 100.0) * line.taleptutar;
                stopajtutar = stopajtutar.toFixed(2);
                setFieldPayload["stopajtutar"] = stopajtutar;

                setFieldPayload["kdv"] = line.kdv;
                var toplamkdv = ((line.kdv) / 100.0) * line.taleptutar;
                toplamkdv = toplamkdv.toFixed(2);
                setFieldPayload["kdvtutari"] = toplamkdv;

                setFieldPayload["tevkifat"] = changeObj.selectedObject;
                var toplamtevkifat = (changeObj.selectedObject / 100.0) * toplamkdv;
                toplamtevkifat = toplamtevkifat.toFixed(2);
                setFieldPayload["tevkifattutari"] = toplamtevkifat;

                setFieldPayload["tevkifatKodAciklama"] = line.tevkifatKodAciklama;
                setFieldPayload["hesaplananodmetutari"] = line.taleptutar - toplamtevkifat - stopajtutar;

                this.$store.commit('SetFields', setFieldPayload);

                this.UpdateTotal();


            },


            sal_kalem_detay_Row_Changed: function ()
            {
                this.UpdateTotal();
            },
            sal_kurlar_Row_Changed: function (row)
            {
                if (row.rowdata.tip == 'SBST')
                {
                    this.ClearExtDataFieldReadOnly('sal_kurlar', row.rowdata.rowid, 'dovizkur');
                }
                else
                {
                    this.SetExtDataFieldReadOnly('sal_kurlar', row.rowdata.rowid, 'dovizkur');
                    this.ChangeCurrencyRate(row.rowdata.rowid);
                }
                this.UpdateTotal();
            },
            ChangeCurrencyRate: function (rowid)
            {

                var exdataItems = this.GetExdataItems('sal_kurlar');
                var self = this;
                this.GetCurrencyRateFromData(exdataItems[rowid].doviz, exdataItems[rowid].tarih, function (data)
                {
                    exdataItems[rowid].dovizkur = data[exdataItems[rowid].tip];

                    var payload = {};
                    payload.tableName = "sal_kurlar";
                    payload.data = exdataItems;
                    payload.maxLen = exdataItems.length;
                    self.$store.commit('SetExtdataData', payload);
                });
            },
            UpdateTotal: function ()
            {
                var kalem_detay = this.GetExdata('sal_kalem_detay');
                var toplamtutar = 0, geneltoplam = 0, toplamkdv = 0, kdvharic = 0;
                var stopajToplam = 0;
                var tevkifatToplam = 0;

                for (var i = 0; i < kalem_detay.data.length; i++)
                {
                    var kalem = kalem_detay.data[i];
                    var tutar = this.GetCurrencyRate(kalem.doviz) * kalem.alisfiyat * kalem.talepmiktar;
                    var kdvTutar = tutar * kalem.kdv / 100;
                    geneltoplam += tutar;
                    kdvharic += tutar;
                    toplamkdv += kdvTutar;
                    toplamtutar += tutar + kdvTutar;

                    var stopajOran = kalem.stopaj;
                    if (!stopajOran) stopajOran = 0;
                    var tevkifatOran = kalem.tevkifat;
                    if (!tevkifatOran) tevkifatOran = 0;

                    stopajToplam += tutar * (stopajOran / 100.0);
                    tevkifatToplam += kdvTutar * (tevkifatOran / 100.0);

                }

                var payload = {};
                payload.geneltoplam = (geneltoplam + toplamkdv).toFixed(2);
                payload.kdvharic = kdvharic.toFixed(2);
                payload.toplamkdv = toplamkdv.toFixed(2);
                payload.toplamtutar = (toplamtutar.toFixed(2) - stopajToplam.toFixed(2) - tevkifatToplam.toFixed(2)).toFixed(2);
                payload.kdvtevkifat = tevkifatToplam.toFixed(2);
                payload.stopaj = stopajToplam.toFixed(2);

                this.$store.commit('SetFields', payload);

            },
            RowButtonClicked: function (payload)
            {
                console.log("Row Clicked in fatuta payload:", payload);
                if (payload.button.name == "detaybutton")
                {
                    this.OpenDynamicForm(payload);
                }
            },

            OpenDynamicForm: function (payload)
            {
                if (payload != undefined && payload.item != undefined && payload.item.id != undefined)
                {
                    var setFieldPayload = {};

                    setFieldPayload.form = 'dynamicFormData';
                    setFieldPayload.id = payload.item.id;

                    var line = payload.item;

                    setFieldPayload["taleptutar"] = line.taleptutar;
                    setFieldPayload["stopaj"] = line.stopaj;
                    var stopajtutar = (line.stopaj / 100.0) * line.taleptutar;
                    stopajtutar = stopajtutar.toFixed(2)
                    setFieldPayload["stopajtutar"] = stopajtutar;

                    setFieldPayload["kdv"] = line.kdv;
                    var toplamkdv = ((line.kdv) / 100.0) * line.taleptutar;
                    toplamkdv = toplamkdv.toFixed(2);
                    setFieldPayload["kdvtutari"] = toplamkdv;

                    setFieldPayload["tevkifat"] = line.tevkifat;
                    var toplamtevkifat = (line.tevkifat / 100.0) * toplamkdv;
                    toplamtevkifat = toplamtevkifat.toFixed(2);
                    setFieldPayload["tevkifattutari"] = toplamtevkifat;

                    setFieldPayload["tevkifatKodAciklama"] = line.tevkifatKodAciklama;
                    setFieldPayload["hesaplananodmetutari"] = line.taleptutar - toplamtevkifat - stopajtutar;

                    this.$store.commit('SetFields', setFieldPayload);


                    this.showDetailDialog = true;
                }
            },
            UpdateDynamicForm: function ()
            {

                var formdata = this.GetFormDataFunction("dynamicFormData");
                var id = formdata.id // Holds sal_kalem_detay table id set up in the openDynamicForm
                var exdataItems = this.GetExdataItems('sal_kalem_detay');

                for (var i = 0; i < exdataItems.length; i++)
                {
                    var item = exdataItems[i];
                    if (item.id == formdata.id)
                    {
                        item.stopaj = formdata.stopaj;
                        item.tevkifat = formdata.tevkifat;
                        item.tevkifatKodAciklama = formdata.tevkifatKodAciklama;
                    }
                }
                this.UpdateTotal();
                this.showDetailDialog = false;
            },
        },
        created: function ()
        {

            var reqDoviz = {
                payload: {
                    search: "",
                    tableName: "formdata",
                    parameters: {},
                    column: {
                        itemText: 'text'
                    }
                },
                queryName: "fatura_doviz"
            };
            reqDoviz.success = function (result)
            {
                sal_kalem_detay.columns.doviz.options = [];
                result.data.list.forEach(function (obj)
                {
                    var listItem = {
                        text: obj.dovizkodu,
                        value: obj.dovizkodu.toString(),
                        kur: obj.kur,
                    };
                    sal_kalem_detay.columns.doviz.options.push(listItem);
                });
            };
            reqDoviz.error = function (err)
            {
                console.log(err);
            };

            this.$store.dispatch("GetLOV", reqDoviz);


            fatura_formInfo.Columns.gidertipi.options = [
                { text: this.$root.$i18n.t('OP_PleaseSelect'), value: '' },
                { text: this.$root.$i18n.t('E-FATURA'), value: 'E' },
                { text: this.$root.$i18n.t('Fatura'), value: 'F' },
                { text: this.$root.$i18n.t('Gider Pusulası'), value: 'G' },
                { text: this.$root.$i18n.t('SMM'), value: 'S' },
                { text: this.$root.$i18n.t('Diğer'), value: 'D' }
            ];

            sal_kalem_detay.columns.kdv.options = [
                { text: this.$root.$i18n.t('OP_PleaseSelect'), value: '' },
                { text: "%0", value: 0 },
                { text: "%1", value: 1 },
                { text: "%8", value: 8 },
                { text: "%18", value: 18 }
            ];

            sal_kurlar.columns.tip.options = [
                { text: this.$root.$i18n.t('OP_PleaseSelect'), value: '' },
                { text: "SABİT KUR", value: "SBST" },
                //{ text: "Akbank Muhasebe Döviz Alış", value: "MHBA" },
                //{ text: "Akbank Muhasebe Döviz Satış", value: "MHBS" },
                { text: "TCMB Döviz Alış", value: "TCAK" },
                { text: "TCMB Döviz Satış", value: "TCSK" },
                { text: "Gişe Döviz Alış", value: "AGAK" },
                { text: "Gişe Döviz Satış", value: "AGSK" },
                { text: "Serbest Döviz Alış", value: "ASAK" },
                { text: "Serbest Döviz Satış", value: "ASSK" }
            ];

        },
        mounted: function ()
        {
            this.openallpanel = [0];
            this.$store.commit('updateFormInfo', fatura_formInfo);
            var form = fatura_formInfo.form;
            var whatToShow = this.$route.name.replace(form, '');
            this.$store.commit('SetWhatToShow', { show: whatToShow });
            if (whatToShow == 'form')
            {
                var params = this.$route.params;
                if (params != undefined && params.id != undefined && Number(params.id) > 0)
                {
                    var formid = Number(params.id);

                    if (formid != undefined && formid != 0)
                    {
                        this.$store.commit('SetFormAndFormId', {
                            form: form,
                            formid: formid
                        });
                        this.LoadFormData(formid);
                    }
                }
            }
            else if (whatToShow == 'yeni')
            {
                this.$store.commit('SetFormAndFormId', {
                    form: form,
                    formid: 0
                });
                this.LoadFormData(0);
            } else
            {
                this.$store.commit('SetWhatToShow', { show: whatToShow });
            }
            var self = this;
            this.$store.dispatch('GetUserInfo').then(userInfo =>
            {
                self.username = userInfo.data.AdSoyad;
            });
        },
        computed: {
            FormAttributes: function ()
            {
                return this.GetFormAttributes;
            },
            UserName()
            {
                return this.GetUserName;
            },
            FormLoadingStatus: {
                get: function ()
                {
                    return this.GetFormLoadingStatus;
                }
            },
            GetState: function ()
            {
                return this.$store.getters.GetFormState;
            },
            DynamicFormInfo: function ()
            {
                return dynamic_formInfo;
            }
        },
        watch: {
            FormLoadingStatus: {
                handler: function (val, oldVal)
                {
                    console.log('FormLoadingStatus changed');
                },
                deep: true
            },


            $route: function (nval, oval)
            {
                console.log('WATCH Route :', nval, ' form:', this.formname);
                console.log('WATCH Route this:', this.$route);
                var form = fatura_formInfo.form;
                var whatToShow = this.$route.name.replace(form, '');
                this.$store.commit('SetWhatToShow', { show: whatToShow });
                if (whatToShow == 'form')
                {
                    var params = this.$route.params;
                    if (params != undefined && params.id != undefined && Number(params.id) > 0)
                    {
                        var formid = Number(params.id);

                        if (formid != undefined && formid != 0)
                        {
                            this.$store.commit('SetFormAndFormId', {
                                form: form,
                                formid: formid
                            });
                            this.LoadFormData(formid);
                        }
                    }
                }
                else if (whatToShow == 'yeni')
                {
                    this.$store.commit('SetFormAndFormId', {
                        form: form,
                        formid: 0
                    });
                    this.LoadFormData(0);
                } else
                {
                    this.$store.commit('SetWhatToShow', { show: whatToShow });
                }
            },

        }
    };
</script>
